import { createApp } from 'vue'
import App from './App.vue'
import './style.css'
import router from './router'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import('@/assets/main.css');

/* add icons to the library */
library.add(faUserSecret)
library.add(faBars)
library.add(faFacebook)
library.add(faTwitter)
library.add(faInstagram)






createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(router).mount('#app')
